<template>
  <b-container fluid class="accounts-tabs-height">
    <b-row>
      <b-col cols="1" class="text-center">
        <b-img src="/img/account-icons.png"></b-img>
      </b-col>
      <b-col cols="11" class="my-profile-account">
        <b-row>
          <b-col cols="11">
            <h2>{{ $t('profile.infotexts.mypofile-title') }}</h2>
            <p>
              {{ $t('profile.infotexts.mypofile') }}
            </p>
            <hr />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col cols="2" class="text-center">
            <b-avatar
              variant="info"
              class="profile-image-account"
              :src="avatar"
              size="120px"
            ></b-avatar>
            <b-row>
              <b-col cols="12">
                <b-form-file
                  id="file"
                  class="inputfile"
                  @change="onFileChange"
                  accept="image/jpeg, image/png"
                ></b-form-file>
                <!--jimir3x-->
                <a href="javascript:void(0)" @click="replaceAvatarClick()" class="replace-text replace-text--extra">
                  <v-icon icon="upload" class="mr-2" />
                  <span>{{ $t('buttons.replace') }}</span>
                </a>
                <!--jimir3x-->
              </b-col>
              <b-col cols="12">
                <!--jimir3x-->
                <router-link
                  :to="'/app/user/profile/' + GET_USER_INFO.uid"
                  class="view-as-member-btn view-as-member-btn--extra"
                  >{{ $t('buttons.memberview') }}</router-link
                >
                <!--jimir3x-->
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="9">
            <validation-observer ref="observer">
              <form action="#">
                <validation-provider
                  mode="passive"
                  name="Name"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <div class="form-group">
                    <label for="name">{{
                      $t('profile.contents.myprofile.aboutme.nameLabel')
                    }}</label>
                    <input spellcheck="true"
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control custom"
                    />
                    <div v-if="validationContext.errors[0]" class="text-danger">
                      <i class="imgs icon-warning"></i>
                      {{ validationContext.errors[0] }}
                    </div>
                  </div>
                </validation-provider>
                <validation-provider
                  mode="passive"
                  name="Surname"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <div class="form-group mt-4 mb-0">
                    <label for="surname">{{
                      $t('profile.contents.myprofile.aboutme.surnameLabel')
                    }}</label>
                    <input spellcheck="true"
                      id="surname"
                      v-model="form.surname"
                      type="text"
                      class="form-control custom"
                    />
                  </div>
                  <div v-if="validationContext.errors[0]" class="text-danger">
                    <i class="imgs icon-warning"></i>
                    {{ validationContext.errors[0] }}
                  </div>
                </validation-provider>
                <div class="form-group mt-4 mb-0">
                  <label for="conId">Connectter ID</label>
                  <input spellcheck="true"
                    id="conId"
                    type="text"
                    disabled
                    v-model="GET_USER_INFO.uid"
                    class="form-control"
                    style="background:#edeff2 !important"
                  />
                </div>
                <validation-provider
                  mode="passive"
                  name="Company name"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <div class="form-group mt-4 mb-0">
                    <label for="companyName">{{
                      $t('profile.contents.myprofile.aboutme.companyname')
                    }}</label>
                    <input spellcheck="true"
                      id="companyName"
                      v-model="form.company.name"
                      @keyup="() => $refs.companySearch.setVisible(true)"
                      type="text"
                      autocomplete="off"
                      class="form-control custom"
                    />
                  </div>
                  <company-search 
                    ref="companySearch"
                    v-model="form.company" 
                    :text="form.company.name" 
                  />
                  <div v-if="validationContext.errors[0]" class="text-danger">
                    <i class="imgs icon-warning"></i>
                    {{ validationContext.errors[0] }}
                  </div>
                </validation-provider>
                <div class="form-group mt-4">
                  <label for="title">{{
                    $t('profile.contents.myprofile.aboutme.title')
                  }}</label>
                  <multi-select
                    @select="selectTitle"
                    :placeholder="$t('profile.contents.myprofile.aboutme.ph-0')"
                    id="title"
                    :showLabels="false"
                    :clearOnSelect="false"
                    :allowEmpty="false"
                    label="name"
                    v-model="selectedTitle"
                    :options="titles"
                  >
                  </multi-select>
                </div>
                <validation-provider
                  mode="passive"
                  name="Position"
                  :rules="{ required: positions.length > 0 }"
                  v-slot="validationContext"
                >
                  <div v-if="positions.length > 0" class="form-group mt-4 mb-0">
                    <label for="position">{{
                      $t('profile.contents.myprofile.aboutme.position')
                    }}</label>
                    <multi-select
                      :placeholder="
                        $t('profile.contents.myprofile.aboutme.ph-1')
                      "
                      id="position"
                      :showLabels="false"
                      :clearOnSelect="false"
                      :allowEmpty="false"
                      label="name"
                      v-model="selectedPosition"
                      :options="positions"
                    >
                    </multi-select>
                    <span v-if="isEmptyPosition" class="text-danger">
                      {{ $t('profile.contents.myprofile.aboutme.alert') }}
                    </span>
                  </div>
                  <div v-if="validationContext.errors[0]" class="text-danger">
                    <i class="imgs icon-warning"></i>
                    {{ validationContext.errors[0] }}
                  </div>
                </validation-provider>
                <div class="form-group mt-4">
                  <label for="industry">{{
                    $t('profile.contents.myprofile.aboutme.industry')
                  }}</label>
                  <multi-select
                    :placeholder="$t('profile.contents.myprofile.aboutme.ph-2')"
                    id="industry"
                    :showLabels="false"
                    :clearOnSelect="false"
                    :allowEmpty="false"
                    label="name"
                    v-model="selectedIndustry"
                    :options="industires"
                  >
                  </multi-select>
                </div>
                <div class="form-group mt-4" style="margin-bottom: 55px">
                  <label for="years">{{
                    $t('profile.contents.myprofile.aboutme.experience')
                  }}</label>
                  <multi-select
                    :placeholder="$t('profile.contents.myprofile.aboutme.ph-3')"
                    id="years"
                    :showLabels="false"
                    :clearOnSelect="false"
                    :allowEmpty="false"
                    label="title"
                    v-model="selectedYear"
                    :options="years"
                  >
                  </multi-select>
                </div>

                <div class="row multiple-location-add">
                  <div class="col-md-6 text-left">
                    <h2>
                      {{ $t('profile.contents.myprofile.aboutme.location') }}
                    </h2>
                  </div>

                  <div class="col-md-6 text-right">
                    <h4
                      @click="showAddLocationInput()"
                      style="color: #000"
                      v-if="
                        GET_USER_INFO.group.permissions.max_location >
                          locationList.length
                      "
                    >
                      <img src="/img/add-location.svg" alt="" />
                      {{ $t('buttons.addlocation') }}
                    </h4>
                  </div>

                  <div
                    class="col-md-12 mb-3"
                    v-for="(location, index) in locationList"
                    :key="index"
                  >
                    <div>
                      <span v-if="location.default">{{
                        $t('profile.contents.myprofile.aboutme.primary')
                      }}</span>
                      <span v-else
                        >{{
                          $t('profile.contents.myprofile.aboutme.location')
                        }}
                        {{ index + 1 }}</span
                      >
                      <mapbox-geocoder
                        class="form-input"
                        v-if="locationAddInput == location.id"
                        :placeholder="'Search Location'"
                        access-token="pk.eyJ1IjoiYmVubnl0cm92YXRvIiwiYSI6ImNrZDcwdTVwbTE4amEyem8yZWdkNHN3ZmoifQ.r3Llqtnwfqqju2zfzE-fvA"
                        types="region,district,place,postcode,locality,neighborhood"
                        @mb-result="onResult($event, location.id)"
                      />
                      <b-input-group
                        v-else
                        style="
                          border: 2px solid #dfe4ea;
                          box-sizing: border-box;
                          border-radius: 6px;
                        "
                      >
                        <b-form-input
                          style="border: none; background: #ffffff !important"
                          disabled
                          :value="
                            location.address.state +
                              ',' +
                              location.address.city +
                              ',' +
                              location.address.country
                          "
                        ></b-form-input>

                        <template #append>
                          <b-dropdown
                            class="profile-add-location dropdown-right"
                            id="dropdown-right"
                            variant="outline-primary"

                            
                          >
                            <template #button-content>
                             <svg style="width:24px;height:24px; margin-right:15px;" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 2C15.9 2 19 5.1 19 9C19 14.2 12 22 12 22S5 14.2 5 9C5 5.1 8.1 2 12 2M12 4C9.2 4 7 6.2 7 9C7 10 7 12 12 18.7C17 12 17 10 17 9C17 6.2 14.8 4 12 4M12 11.5L14.4 13L13.8 10.2L16 8.3L13.1 8.1L12 5.4L10.9 8L8 8.3L10.2 10.2L9.5 13L12 11.5Z" />
</svg>
                              <!-- <img
                                src="/img/profile-edit-location-icon.svg"
                                class="img-fluid mr-4"
                                alt=""
                                style="margin-top: -2px"
                              /> -->
                              {{ $t('buttons.edit') }}
                            </template>
                            <b-dropdown-item
                              @click="changeLocationById(location.id)"
                              >{{ $t('buttons.change') }}</b-dropdown-item
                            >
                            <b-dropdown-item 
                              v-if="!location.default"
                              @click="deleteLocation(location.id)"
                              >{{ $t('buttons.remove') }}</b-dropdown-item
                            >
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item
                              v-if="!location.default"
                              @click="makePrimary(location.id)"
                              >{{ $t('buttons.makeprimary') }}</b-dropdown-item
                            >
                          </b-dropdown>
                        </template>
                      </b-input-group>
                    </div>
                  </div>

                  <div class="col-md-12" v-if="addNewLocationInput">
                    <div>
                      <span>{{ $t('buttons.addnewloc') }}</span>
                      <b-input-group>
                        <mapbox-geocoder
                          style="width: 100%"
                          class=""
                          v-if="!NewLocationAddInput"
                          :placeholder="'Search Location'"
                          access-token="pk.eyJ1IjoiYmVubnl0cm92YXRvIiwiYSI6ImNrZDcwdTVwbTE4amEyem8yZWdkNHN3ZmoifQ.r3Llqtnwfqqju2zfzE-fvA"
                          types="region,district,place,postcode,locality,neighborhood"
                          @mb-result="onResult"
                        />
                      </b-input-group>
                    </div>
                  </div>
                </div>

                <b-row class="location-plan-info-wrapper">

                    <b-col cols="1">
                  <img src="/img/account-icons.png" class="">
                  </b-col>
                  <b-col cols="11">
                <p class="location-plan-info">
               {{$t('profile.contents.myprofile.aboutme.location-plan-info')}}  
               
               <span style="color:blue;"><router-link
                to="/app/profile/settings/plan"
              
                >{{ $t('minimap.upgradeplan') }}</router-link
              >
               </span> 
                </p>
                </b-col>
</b-row>           
                <!-- <p v-if="GET_USER_INFO.group.permissions.change_location_after_minute > 0">
                    lokasyon bilgilerini 
                    <span style="color: red;">
                        {{ GET_USER_INFO.group.permissions.change_location_after_minute }}
                    </span>
                    dakikada bir değiştirebilirisiniz
                </p> -->

                <!--<div class="form-groups mt-4">
                  <label for="#">
                    Location 1
                    <span class="required">*</span>
                  </label>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <mapbox-geocoder
                      class="w-75 mr-3"
                      v-if="changeAddress"
                      :placeholder="'Search Location'"
                      access-token="pk.eyJ1IjoiYmVubnl0cm92YXRvIiwiYSI6ImNrZDcwdTVwbTE4amEyem8yZWdkNHN3ZmoifQ.r3Llqtnwfqqju2zfzE-fvA"
                      types="region,district,place,postcode,locality,neighborhood"
                      @mb-result="onresult"
                    />
                    <span
                      v-if="!changeAddress"
                      class="d-bloc w-70"
                      style="width: 70% !important"
                      >{{
                        form.locations.find((x) => x.default).address.route +
                        ", " +
                        form.locations.find((x) => x.default).address.district +
                        ", " +
                        form.locations.find((x) => x.default).address.city +
                        ", " +
                        form.locations.find((x) => x.default).address.country
                      }}</span
                    >
                    <button
                      class="btn-outline-primary btn-sm flex-grow-1 w-30 ml-3"
                      @click.prevent="changeLocation"
                      style="width: 30% !important"
                    >
                      {{ changeAddress ? "Cancel" : "Change Location" }}
                    </button>
                  </div>

                  <small v-if="isEmptyAddress" class="text-danger"
                    >Please select address</small
                  >
                </div> -->
                <validation-provider
                  name="About"
                  :rules="{ required: true, min: 120, max: 1200 }"
                  v-slot="validationContext"
                >
                  <div class="form-group mt-4 mb-0">
                    <label for="aboutMe">{{
                      $t('profile.contents.myprofile.aboutme.about')
                    }}</label>
                    <textarea spellcheck="true"
                      v-model="form.about"
                      rows="5"
                      id="aboutMe"
                      type="text"
                      class="form-control custom"
                      style="min-height:200px"
                    ></textarea>
                  </div>
                   <div class="counter">
                  <div v-if="validationContext.errors[0]" class="text-danger">
                    <i class="imgs icon-warning"></i>
                    {{ validationContext.errors[0] }}
                  </div>
                  <div class="number-back-counter">
                    {{ counter }} / 1200
                  </div>
                  </div>
                </validation-provider>
                <div class="d-flex justify-content-end">
                  <button
                    @click.prevent="updateProfile"
                    class="btn btn-primary my-5"
                  >
                    {{ $t('buttons.savechanges') }}
                  </button>
                </div>
              </form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <sweet-modal class=" modal-x" id="profileLocation" ref="profileLocation">
      <h1>{{ $t('profile.contents.myprofile.aboutme.modal-title') }}</h1>
      <hr style="margin-top: -5px; margin-bottom: 24px" />
      <mapbox-map
        ref="selectmap"
        style="height: 450px"
        v-if="location.center"
        :access-token="this.mapAccessToken"
        :map-style="this.mapBoxStyle"
        :center="location.center"
        :maxBounds="location.bbox"
        :zoom="13"
        @mb-created="map => (selectMap = map)"
        @mb-drag="() => (markerpos = selectMap.getCenter().toArray())"
        @mb-dragend="dragEndFunction"
      >
        <mapbox-marker
          :lng-lat="markerpos"
          :draggable="false"
          color="blue"
        />
      </mapbox-map>
      <div class="d-flex my-4 justify-content-end">
        <button
          v-if="this.idToChange !== null"
          :disabled="!selectPlaced"
          @click="selectPlace()"
          class="btn btn-primary"
        >
          {{ $t('buttons.save') }}
        </button>
        <button
          v-else
          :disabled="!selectPlaced"
          @click="selectPlace()"
          class="btn btn-primary"
        >
          {{ $t('buttons.select') }}
        </button>
      </div>
    </sweet-modal>
    <!-- <b-modal
      :hide-header-close="true"
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      id="mapModal"
      size="lg"
      title="Select Place"
    >
      <mapbox-map
        ref="selectmap"
        style="height: 450px"
        v-if="location.center"
        :access-token="this.mapAccessToken"
        :map-style="this.mapBoxStyle"
        :center="location.center"
        :maxBounds="location.bbox"
        :zoom="13"
      >
        <mapbox-marker
          :lng-lat="markerpos"
          :draggable="true"
          @mb-dragend="dragEndFunction"
          color="blue"
        />
      </mapbox-map>
      <div class="d-flex my-4 justify-content-end">
        <button
          :disabled="!selectPlaced"
          @click="selectPlaced"
          class="btn btn-primary"
        >
          Select
        </button>
      </div>
    </b-modal> -->
  </b-container>
  <!-- <div class="container">
    <div v-if="loading" class="row">
      <div class="col-8">
        <loading :loading="loading" />
      </div>
    </div>
    <div v-if="!loading" class="row justify-content-between">
      <div class="col-7">
        <div class="row">
          <div class="col-3">
            <div class="sub-title">
              <h3 class="ml-4 mt-4 mb-5">Profile</h3>
            </div>
            <div class="setting-avatar">
              <img
                :src="avatar"
                alt=""
                style="width: 118px; height: 118px"
                class="rounded-circle"
              />
            </div>
            <label class="myLabel mt-4">
              <b-form-file
                id="file"
                class="inputfile"
                @change="onFileChange"
                accept="image/jpeg, image/png"
              ></b-form-file>
              <span> <v-icon icon="upload" /> Change Avatar</span>
            </label>
          </div>
          <div class="col-9 pt-5">
            <validation-observer ref="observer">
              <form action="#">
                <validation-provider
                  mode="passive"
                  name="Name"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <div class="form-group">
                    <label for="name">Name</label>
                    <input spellcheck="true"
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control custom"
                    />
                    <div v-if="validationContext.errors[0]" class="text-danger">
                      <i class="imgs icon-warning"></i>
                      {{ validationContext.errors[0] }}
                    </div>
                  </div>
                </validation-provider>
                <validation-provider
                  mode="passive"
                  name="Surname"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <div class="form-group mt-4 mb-0">
                    <label for="surname">Surname</label>
                    <input spellcheck="true"
                      id="surname"
                      v-model="form.surname"
                      type="text"
                      class="form-control custom"
                    />
                  </div>
                  <div v-if="validationContext.errors[0]" class="text-danger">
                    <i class="imgs icon-warning"></i>
                    {{ validationContext.errors[0] }}
                  </div>
                </validation-provider>
                <div class="form-group mt-4 mb-0">
                  <label for="conId">Connectter ID</label>
                  <input spellcheck="true"
                    id="conId"
                    type="text"
                    disabled
                    v-model="GET_USER_INFO.uid"
                    class="form-control"
                  />
                </div>
                <validation-provider
                  mode="passive"
                  name="Company name"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <div class="form-group mt-4 mb-0">
                    <label for="companyName">Company Name</label>
                    <input spellcheck="true"
                      id="companyName"
                      v-model="form.company.name"
                      type="text"
                      class="form-control custom"
                    />
                  </div>
                  <div v-if="validationContext.errors[0]" class="text-danger">
                    <i class="imgs icon-warning"></i>
                    {{ validationContext.errors[0] }}
                  </div>
                </validation-provider>
                <div class="form-group mt-4">
                  <label for="title">Title</label>
                  <multi-select
                    @select="selectTitle"
                    :placeholder="'Select title'"
                    id="title"
                    :showLabels="false"
                    :clearOnSelect="false"
                    :allowEmpty="false"
                    label="name"
                    v-model="selectedTitle"
                    :options="titles"
                  >
                  </multi-select>
                </div>
                <validation-provider
                  mode="passive"
                  name="Position"
                  :rules="{ required: positions.length > 0 }"
                  v-slot="validationContext"
                >
                  <div v-if="positions.length > 0" class="form-group mt-4 mb-0">
                    <label for="position">Position</label>
                    <multi-select
                      :placeholder="'Select Position'"
                      id="position"
                      :showLabels="false"
                      :clearOnSelect="false"
                      :allowEmpty="false"
                      label="name"
                      v-model="selectedPosition"
                      :options="positions"
                    >
                    </multi-select>
                    <span v-if="isEmptyPosition" class="text-danger">
                      Position is required
                    </span>
                  </div>
                  <div v-if="validationContext.errors[0]" class="text-danger">
                    <i class="imgs icon-warning"></i>
                    {{ validationContext.errors[0] }}
                  </div>
                </validation-provider>
                <div class="form-group mt-4">
                  <label for="industry">Industry</label>
                  <multi-select
                    :placeholder="'Select industry'"
                    id="industry"
                    :showLabels="false"
                    :clearOnSelect="false"
                    :allowEmpty="false"
                    label="name"
                    v-model="selectedIndustry"
                    :options="industires"
                  >
                  </multi-select>
                </div>
                <div class="form-group mt-4">
                  <label for="years">Years of experience</label>
                  <multi-select
                    :placeholder="'Select Year'"
                    id="years"
                    :showLabels="false"
                    :clearOnSelect="false"
                    :allowEmpty="false"
                    label="title"
                    v-model="selectedYear"
                    :options="years"
                  >
                  </multi-select>
                </div>
                <div class="form-groups mt-4">
                  <label for="#">
                    Location
                    <span class="required">*</span>
                  </label>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <mapbox-geocoder
                      class="w-75 mr-3"
                      v-if="changeAddress"
                      :placeholder="'Search Location'"
                      access-token="pk.eyJ1IjoiYmVubnl0cm92YXRvIiwiYSI6ImNrZDcwdTVwbTE4amEyem8yZWdkNHN3ZmoifQ.r3Llqtnwfqqju2zfzE-fvA"
                      types="region,district,place,postcode,locality,neighborhood"
                      @mb-result="onresult"
                    />
                    <span v-if="!changeAddress" class="d-bloc w-70">{{
                      form.address.route +
                      ", " +
                      form.address.district +
                      ", " +
                      form.address.city +
                      ", " +
                      form.address.country
                    }}</span>
                    <button
                      class="btn-outline-primary btn-sm flex-grow-1 w-30 ml-3"
                      @click.prevent="changeLocation"
                    >
                      {{ changeAddress ? "Cancel" : "Change Location" }}
                    </button>
                  </div>

                  <small v-if="isEmptyAddress" class="text-danger"
                    >Please select address</small
                  >
                </div>
                <validation-provider
                  name="About"
                  :rules="{ required: true, max: 120 }"
                  v-slot="validationContext"
                >
                  <div class="form-group mt-4 mb-0">
                    <label for="aboutMe">About</label>
                    <textarea spellcheck="true"
                      v-model="form.about"
                      rows="5"
                      id="aboutMe"
                      type="text"
                      class="form-control custom"
                    ></textarea>
                  </div>
                  <div v-if="validationContext.errors[0]" class="text-danger">
                    <i class="imgs icon-warning"></i>
                    {{ validationContext.errors[0] }}
                  </div>
                </validation-provider>
                <div class="d-flex justify-content-end">
                  <button
                    @click.prevent="updateProfile"
                    class="btn btn-primary my-5"
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="setting-info-card">
          <div class="icon">
            <v-icon :icon="['fac', 'bulb']" />
          </div>
          <h3 class="title">Why is your profile important?</h3>
          <hr class="w-75" />
          <p>
            Your profile helps you get found, show business credibility to your
            target audience and it is a way of engaging with people who often
            become valuable connections.
          </p>
        </div>
      </div>
    </div>
    <b-modal
      :hide-header-close="true"
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      id="mapModal"
      size="lg"
      title="Select Place"
    >
      <mapbox-map
        ref="selectmap"
        style="height: 450px"
        v-if="location.center"
        :access-token="this.mapAccessToken"
        :map-style="this.mapBoxStyle"
        :center="location.center"
        :maxBounds="location.bbox"
        :zoom="13"
        @click="click"
      >
        <mapbox-marker :lng-lat="markerpos" color="blue" />
      </mapbox-map>
      <div class="d-flex my-4 justify-content-end">
        <button
          :disabled="!selectPlace"
          @click="selectPlaced"
          class="btn btn-primary"
        >
          Select
        </button>
      </div>
    </b-modal>
  </div> -->
</template>

<script>
import getProfile from '@/graphql/me/profile/getProfile.graphql';
import updateSelectedLocation from '@/graphql/me/profile/updateLocation.graphql';
import loading from '@/components/global/loading';
import _ from 'lodash';
import { toBase64 } from '@/helpers/func';
import updateProfile from '@/graphql/me/profile/updateProfile.graphql';
import myLocations from '@/graphql/me/profile/getMyLocations.gql';
import addNewLocation from '@/graphql/me/profile/addLocation.gql';
import setDefault from '@/graphql/me/profile/setDefaultLocation.gql';
import deleteMyLocation from '@/graphql/me/profile/deleteLocation.gql';
import companySearch from '@/components/global/companySearch.vue';

export default {
  components: {
    loading,
    companySearch
  },
  data() {
    return {
      idToChange: null,
      NewLocationAddInput: false,
      locationAddInput: false,
      addNewLocationInput: false,
      isEmptyPosition: false,
      changeAddress: false,
      selectPlaced: false,
      selectPlacedUpdate: false,
      isEmptyAddress: false,
      location: {},
      markerpos: [],
      selectMap: null,
      city: '',
      place: '',
      changeAvatar: false,
      markers: [],
      locationList: [],
      loading: true,
      form: {
        company: {
          name: null,
          key: null
        },
      },
      titles: [],
      selectedTitle: null,
      selectedPosition: null,
      positions: [],
      selectedYear: null,
      avatarFile: null,
      avatar: null,
      industires: null,
      selectedIndustry: null,
    };
  },
  computed: {
    counter() {
        return _.size(this.form.about);
    }
  },
  methods: {
    changeLocationById(id) {
      this.locationAddInput = id;
    },
    addAnotherLocation() {
      console.log('asdsadas');
    },

    async updateProfile() {
      this.$refs.observer.validate().then(async success => {
        if (!success || (this.isEmptyAddress && !this.selectPlaced)) {
          return;
        }
        try {
          this.loading = true;
          let payload = {
            name: this.form.name,
            surname: this.form.surname,
            company_name: this.form.company.name,
            company_key: this.form.company.key,
            title_id: this.selectedTitle.id,
            industry_id: this.selectedIndustry.id,
            years_of_experience: this.selectedYear.id,
            about: this.form.about,
          };
          if (this.selectedPosition) {
            payload.position_id = this.selectedPosition.id;
          }
          if (this.changeAvatar) {
            payload.avatar = this.avatarFile;
          }
          let data = await this.$apollo.mutate(updateProfile, {
            member: payload,
          });

          let response = data('**.update');

          if (response.name) {
            await this.getProfile();
            if (this.changeAddress) {
              this.changeLocation();
            }
            let par = {
              message: '✅',
              title: this.$t("Toaster.profile-updated-title"),
              type: 'success',
              hide: false,
            };
            this.makeToast(par);
          }
          this.loading = false;
        } catch (e) {
          
          return this.$bvToast.toast(this.$t(e.message), {
            title: this.$t("Toaster.error-title"),
            variant: 'danger',
            autoHideDelay: 1350,
          });
        }
      });
    },

    selectPlace() {
      this.$refs.profileLocation.close();
      if (this.idToChange !== null) {
        this.updateLocation(this.idToChange);
        return;
      }

      this.addLocation();
    },

    dragEndFunction: function(e) {
      this.selectPlaced = true;
      this.addNewLocationInput = false;
    },

    onResult: function({ result }, id) {
      this.idToChange = id || null;
      this.location = result;
      this.markerpos = result.center;
      this.$refs.profileLocation.open();
    },

    async selectTitle(selectedOption) {
      let id = selectedOption.id;
      this.selectedPosition = null;
      await this.getPositions(id);
    },
    async getProfile() {
      try {
        let data = await this.$apollo.query(getProfile);
        let response = data('**.get');
        this.selectedYear = this.years.find(
          item =>
            item.id ===
            response.years_of_experience.low +
              '-' +
              response.years_of_experience.high
        );
        this.markerpos = [0, 1];
        if (response.poisition && response.position.id) {
          await this.getPositions(response.title.id);
          this.selectedPosition = this.positions.find(
            item => item.id === response.position.id
          );
        }
        this.form = response;
      } catch (e) {
         return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "secondary",
          autoHideDelay: 1350,
        });
      }
    },
    async getTitles() {
      try {
        let data = await this.$store.dispatch('inquiries/getTitles', {
          vm: this,
        });
        let response = data('**.titles');
        this.titles = response;
      } catch (e) {
        //console.log(e)
      }
    },
    async getPositions(id) {
      try {
        this.positions = [];
        let payload = {
          title_id: id,
        };
        let data = await this.$store.dispatch('inquiries/getPositions', {
          vm: this,
          payload,
        });
        let response = data('**.positions');
        //console.log(response)
        this.positions = response;
      } catch (e) {
        // console.log(e)
      }
    },
    showAddLocationInput() {
      this.addNewLocationInput = true;
      this.locationAddInput = false;
    },
    async makePrimary(id) {
      try {
        await this.$apollo.mutate(setDefault, { id });
        this.getMyLocation();
      } catch (e) {
      return this.$bvToast.toast(this.$t(e.message), {
         title: this.$t("Toaster.success-title"),
          variant: "success",
          autoHideDelay: 1350,
        });
      }
    },
    async deleteLocation(id) {
      try {
        await this.$apollo.mutate(deleteMyLocation, { id });
        this.getMyLocation();
      } catch (e) {
        alert(e.message);
      }
    },
    async addLocation() {
      try {
        let params = {
          location: {
            name: 'Location',
            default: false,
            point: {
              lon: this.markerpos[0],
              lat: this.markerpos[1],
            },
          },
        };

        let data = await this.$apollo.mutate(addNewLocation, params);

        this.NewLocationAddInput = false;

        this.idToChange = null;
        this.getMyLocation();

        //console.log(this.locationList);
      } catch (e) {
        console.log(e);
      }
    },
    async updateLocation(id) {
      try {
        let params = {
          location: {
            id: id,
            default: this.locationList.find(x => x.id == id).default,
            name: 'location',
            point: {
              lon: this.markerpos[0],
              lat: this.markerpos[1],
            },
          },
        };

        await this.$apollo.mutate(updateSelectedLocation, params);
        this.NewLocationAddInput = false;
        this.locationAddInput = false;
        console.log(this.locationAddInput);
        this.idToChange = null;
        this.getMyLocation();
      } catch (e) {
         return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "secondary",
          autoHideDelay: 1350,
        });
      }
    },
    async getMyLocation() {
      try {
        let data = await this.$apollo.query(myLocations, {});
        let response = data('**.list');

        //this.NewLocationAddInput = false;

        this.locationList = response.sort((a, b) =>
          a.default == true ? -1 : b.default == true ? 1 : 0
        );
      } catch (e) {
        //console.log(e);
      }
    },
    fetchData() {
      let titleId = this.form.title.id;
      let position_id = this.form.poisition ? this.form.position.id : null;
      let position;
      let title = this.titles.find(item => item.id === titleId);
      this.selectedTitle = title;
      if (position_id) {
        position = this.positions.find(item => item.id === position_id);
        this.selectedPosition = position;
      }
    },
    async onFileChange(e) {
      let files = e.target.files;
      //console.log(files)
      if (files.length) {
        if (!/image\/(jpeg|jpg|png)/i.test(files[0].type)) {
          return this.$bvToast.toast('Geçersiz Dosya Türü', {
            title: 'Hata',
            variant: 'danger',
            solid: true,
          });
        }
        this.avatarFile = files[0];
        //console.log(this.avatarFile)
        let result = await toBase64(files[0]);
        this.avatar = result;
        this.changeAvatar = true;
      }
    },
    async getIndustries() {
      try {
        let par = {
          type: 'MEMBER',
        };
        let response = await this.$store.dispatch('inquiries/getIndustries', {
          vm: this,
          payload: par,
        });
        this.industires = response;
        this.selectedIndustry = this.industires.find(
          item => item.id === this.form.industry.id
        );
      } catch (e) {
        //console.log(e);
      }
    },

    replaceAvatarClick() {
        document.querySelector('#file').click();
    }
  },
  async created() {
    this.loading = true;
    await this.getProfile();
    await this.getTitles();
    await this.getIndustries();
    await this.getMyLocation();
    this.fetchData();
    this.avatar = this.GET_USER_INFO.avatar + '?size=autox640';
    //console.log(this.form)
    this.loading = false;
  },
};
</script>
<style lang="scss">
label.myLabel input[type='file'] {
  position: absolute;
  top: -1000px;
}

/***** Example custom styling *****/
.myLabel {
  border: none;
  border-radius: 4px;
  padding: 2px 5px;
  margin: 2px;
  background: transparent;
  display: inline-block;
  cursor: pointer;
  color: #198cff !important;
}
.myLabel:hover {
  background: transparent;
}
.myLabel:active {
  background: transparent;
}
.myLabel :invalid + span {
  color: #198cff !important;
}
.myLabel :valid + span {
  color: #198cff !important;
}
</style>
